<template>
  <div class="scanner-container">
    <!--   yichang   {{str}}-->

<!--    <div>errresult:{{ result }}</div>-->
<!--    <input type="file" class="capture"  capture="camera" />-->
    <div v-show="!isLoading">
      <video poster="data:image/gif,AAAA" ref="scanner"></video>
      <div class="overlay-element"></div>
      <div class="laser"></div>
    </div>
<!--   <div>result:{{ result }}</div>-->
  </div>
</template>

<script>
import {BrowserMultiFormatReader, Exception} from "@zxing/library";

export default {
  name: "stream-barcode-reader",

  data() {
    return {
      str: '',
      result: '',
      errresult: '',
      isLoading: true,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported:
          navigator &&
          navigator.mediaDevices &&
          "enumerateDevices" in navigator.mediaDevices
    };
  },

  mounted() {
    console.log("mounted");
    if (!this.isMediaStreamAPISupported) {
      this.str = 'Media Stream API is not supported'
      throw new Exception("Media Stream API is not supported");
      return;
    }

    this.start();
    this.$refs.scanner.oncanplay = event => {
      this.isLoading = false;
      this.$emit("loaded");
    };
  },

  beforeDestroy() {
    this.codeReader.reset();
  },

  methods: {
    start() {
      this.codeReader.decodeFromVideoDevice(
          undefined,
          this.$refs.scanner,
          (result, err) => {

            if (result) {
              this.result = result;
              this.$emit("decode", result.text);
            } else {
              this.errresult = err;
            }

          }
      );
    }
  }
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
}

.scanner-container {

  position: relative;
}

.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);

  -webkit-clip-path: polygon(
      0% 0%,
      0% 100%,
      20% 100%,
      20% 20%,
      80% 20%,
      80% 80%,
      20% 80%,
      20% 100%,
      100% 100%,
      100% 0%
  );
  clip-path: polygon(
      0% 0%,
      0% 100%,
      10% 100%,
      10% 10%,
      90% 10%,
      90% 90%,
      10% 90%,
      10% 100%,
      100% 100%,
      100% 0%
  );
}

.laser {
  width: 80%;
  margin-left: 10%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 10%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}

@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(150px);
  }
}

@keyframes scanning {
  50% {
    -webkit-transform: translateY(150px);
    transform: translateY(320px);
  }
}
</style>
